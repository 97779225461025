import {getConfig} from '../../../i18n/Metronici18n'
import {urlPattern} from '../regexs'

export const establishOwnerFromDomain = () => {
  const host = establishHostFromUrl()
  // console.log('establishOwnerFromDomain', host?.replace('www.', ''))

  switch (host?.replace('www.', '')) {
    case 'vectorchina.biz':
      return 3
    case 'vectorlogistic.net':
      return 6
    case 'vector-usa.biz':
    case 'localhost':
    case 'vector.org':
      return 1
    case 'pokupka.eu':
      return 8
    case 'dostavka.es':
      return 7
    case 'bosnia.crauzer.com':
      return 11
    default:
      return 6
  }
}

export const getHostUrl = () => {
  const url = new URL(window.location.href)
  const host = url.hostname.replace(/((www.)?my.)/, '')
  return host
}

export const establishHostFromUrl = () => {
  const host = getHostUrl()
  // console.log('host', host)
  // vectorlogistic.net

  const isValidUrl = urlPattern.test(host)

  if (host === 'localhost') {
    return 'localhost'
  } else if (isValidUrl) {
    return 'www.' + host
  } else {
    return ''
  }
}

export const establishBackendFromUrl = () => {
  const host = getHostUrl()
  // console.log('host', host)
  // vectorlogistic.net

  const isValidUrl = urlPattern.test(host)

  const exceptions = ['vectorlogistic.net', 'bosnia.crauzer.com'];
  if (isValidUrl && !exceptions.includes(host)) {
    return 'www.' + host
  } else {
    return host
  }
}

export const establishFileEndpoint = () => {
  const host = getHostUrl()

  const isValidUrl = urlPattern.test(host)

  if (isValidUrl) {
    return `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_FILES}${host}`
  } else {
    return process.env.REACT_APP_FILE_ENDPOINT
  }
}
export const countries: {uk: string; ru: string; en: string; intl: string}[] = [
  {uk: 'Китаї', ru: 'Китае', en: 'China', intl: 'CHINA'},
  {uk: 'США', ru: 'США', en: 'USA', intl: 'USA'},
  {uk: 'Боснія', ru: 'Босния', en: 'Bosnia', intl: 'BOSNIA'},
]

// kostyl' till we come up with a way to pass the name of country
export const establishCountryFromOwner = () => {
  const host = establishHostFromUrl()
  const {selectedLang} = getConfig()

  switch (host?.replace('www.', '')) {
    case 'vectorchina.biz':
      return {
        name: countries[0][selectedLang as keyof typeof countries[0]],
        intl: countries[0].intl,
      }
    case 'vectorlogistic.net':
      return {
        name: countries[1][selectedLang as keyof typeof countries[1]],
        intl: countries[1].intl,
      }
    case 'vector-usa.biz':
    case 'vector.org':
      return {
        name: countries[1][selectedLang as keyof typeof countries[1]],
        intl: countries[1].intl,
      }
    case 'bosnia.crauzer.com':
      return {
        name: countries[1][selectedLang as keyof typeof countries[1]],
        intl: countries[1].intl,
      }
    default:
      return {
        name: countries[1][selectedLang as keyof typeof countries[1]],
        intl: countries[1].intl,
      }
  }
}
