import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {IBasicSelect} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {Notification} from '../../../modules/custom/Notification'
import {useListView} from '../core/ListViewProvider'
import {AddTrackForm} from '../core/_models/_tracks-models'

interface Props {
  values: AddTrackForm
  receiversListData: IBasicSelect[]
}

export function TrackReceiverInput({values, receiversListData}: Props) {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <div className='fv-row mb-5'>
        <InputTemplate
          inputName='receiver'
          disabled={!!itemIdForUpdate}
          required={true}
          type='select'
          value={values.receiver}
          title={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.RECEIVERS_TITLE',
          })}
          addPrompt={true}
          promptText={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.RECEIVER_PROMPT',
          })}
          titleFontSize='fs-5'
          reactSelectprops={{
            options: receiversListData,
            currentValue: receiversListData.find(
              (item) => item.value === values.receiver.value
            ),
            optionFormat: ({value, label, address, city, region}) => (
              <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
                <div className='d-flex flex-column text-gray-800'>
                  <strong>{label}</strong>
                  {(address || city || region) && (
                    <span className='fs-6 fw-normal'>
                      <FormattedMessage
                        id='TRACKS.ADD_MODAL.FORM.RECEIVER_ADDRESS'
                        values={{address, city, region}}
                      />
                    </span>
                  )}
                </div>
              </div>
            ),
            defaultValue: receiversListData[0],
            selectName: 'receiver',
            mock: false,
            isLoading: false,
            onInputChange: () => {},
            setFieldValue: setFieldValue,
            setObject: true,
            placeholder: intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.RECEIVERS_PLACEHOLDER',
            }),
            noOptionsMessage: intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.NO_RECEIVERS_MESSAGE',
            }),
          }}
        />

        <div className='fv-row mb-10'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.DELIVERY_PROMPT',
            })}
            link={{
              text: intl.formatMessage({
                id: 'TRACKS.ADD_MODAL.FORM.DELIVERY_PROMPT.LINK_WORD',
              }),
              url: '/receivers/list',
            }}
          />
        </div>
      </div>
    </>
  )
}
